@import "typo";
@import "color";
@import "utils";

.ss-modal {
  padding: 20px 20px 0px 20px;

  .ss-modal-header {
    @include subtitle-text-2;
  }
  .ss-modal-body {
    @include body-text-3;
    padding: 16px 0;

  }
  .ss-modal-footer {
    border-top: 1px solid $ss-gray-30;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 16px 0px 16px 0px;
    &.center {
      justify-content: center;
    }
  }
}

.ss-modal-2 {
  @extend .ss-modal;

  .ss-modal-lr {
    display: flex;
    padding-bottom: 16px;
    &.ss-modal-lr-has-bottom-child{
      height: 376px;
      border-bottom: none;
    }
    .ss-modal-l {
      width: 282px;
      padding-right: 16px;
      border-right: 1px solid $ss-gray-30;
    }
    .ss-modal-r {
      padding-left: 16px;
      flex-grow: 1;
    }
    .ss-modal-header {
      margin-bottom: 16px;
    }
  }
  .ss-modal-b{
    margin: 16px 0 16px 0;
    padding:20px;
    width: 100%;
    height: 160px;
    background-color: $ss-gray-10;
    border: 1px dashed #EEEEEE;
    border-radius: 4px;
    overflow-y: auto;
    }
}

.ss-modal-blank {
 .ss-modal-body {

 }
}

.modal-header--profile{
  font-size: 18px;
  line-height: 28px;
  padding: 0px 32px;
  display: flex;
  align-items: center;
  padding:20px 32px 24px 32px;
  justify-content: space-between;
  .icon-modal-header--profile{
    width: 14px;
    height: 14px;
    cursor: pointer;
    fill: $ss-gray-60;
  }
}
.modal-footer--profile{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 24px 32px;
  font-size: 14px;
  line-height: 24px;
  gap: 16px;
  .btn-submit-modal-footer--profile{
    border:none;
    background-color: $ss-blue-50;
    border-radius: 4px;
    width: 160px;
    height: 40px;
    color: $ss-white;
    cursor: pointer;
  }
  .btn-close-modal-footer--profile{
    border:none;
    background-color: #a1a6ad;
    border-radius: 4px;
    width: 160px;
    height: 40px;
    color: $ss-white;
    cursor: pointer;
  }
}

@media (min-width: 1080px){
  .image-upload-modal{
    background-color: $ss-white;
    width: 400px;
    margin:auto;
    margin-top: 30px;
    boxShadow: 0px 10px 37px rgba(0, 0, 0, 0.15);
    borderRadius: 8px;
    padding: 0;
    position: relative;
    maxHeight: 90%;
    box-shadow: 0px 10px 37px rgba(0, 0, 0, 0.15);
    :focus {outline:0;}
  }
}
@media (max-width: 1079px) {
  .image-upload-modal{
    margin: auto;
    height: 100%;
  }
    .image-upload-modal-box {
      height: 100%;
      background-color: white;
    }
  .modal-header--profile{
    width: 100%;
    height: 60px;
    padding: 14px 16px;
    .modal-header--profile-title{
      margin: 0 auto;
    }
  }
  .modal-body--profile{
    width: 100%;
    height: calc(100% - 144px);
  }
  .modal-footer--profile{
    justify-content: center;
  }

}
