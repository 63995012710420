@import "./typo";
@import "./color";
@import "./utils";

.link-tag {
  text-decoration: none;
  color: inherit;

  &.hover-underline{
    &:hover {
      text-decoration: underline;
    }
  }
}