@import "../../base/typo";
@import "../../base/color";
@import "../../base/utils";

.ss-default-profile-modal {
  .modal-only-body {
    padding: 60px;
    padding-bottom: 40px;

    .top {
      display: flex;
      justify-content: flex-end;

      svg {
        cursor: pointer;
      }
    }

    .mid {
      margin-bottom: 16px;
      text-align: center;
    }
    .bottom {
      margin-bottom: 32px;
    }

    .sub-title {
      @include body-text-4;
      color: $ss-gray-90;
      margin-top: 12px;
      text-align: center;
    }
    .title {
      margin-top: 4px;
      @include h6;
      color: $ss-gray-100;
      text-align: center;

      &.verbose {
        @include h7;
        color: $ss-gray-100;
      }
    }
    .title-only {
      @include h6;
      color: $ss-gray-100;
    }
  }
}
