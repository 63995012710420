@import "color";
@import "typo";
@import "utils";


.btn {
  border: none;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-border-radius: 4px;
  cursor: pointer;
  user-select: none;

  .content-box {
    @extend .flex-box;
    justify-content: center;

    .content-with-icon-rear {
      margin-right: 6px;
    }

    .content-with-icon-front {
      margin-left: 6px;
    }
  }

  svg {
    display: block;
  }
}

.btn--text {
  @extend .btn;
  background-color: $ss-gray-10;
  color: $ss-gray-70;
}

.btn--sm {
  @include btn-text-4;
  padding: 6px 12px;
}

.btn--md {
  @include btn-text-4;
  padding: 8px 14px;
}

.btn--lg {
  @include btn-text-3;
  padding: 10px 16px;
}

.btn--xl {
  @include btn-text-2;
  padding: 10px 16px 12px 16px;
}

.btn--xxl {
  @include btn-text-2;
  padding: 12px 16px 14px 16px;
}

.btn--xl-landing {
  @include btn-text-1;
  padding: 11px 24px;
}

.btn--filled {
  @extend .btn;
  background-color: $ss-blue-50;
  color: $ss-white;

  &:disabled {
    background-color: $ss-blue-20;
  }
}

.btn--cancel {
  @extend .btn;
  background-color: inherit;
  color: $ss-gray-70;
}

.btn--m-cancel {
  @extend .btn;
  background-color: $ss-gray-50;
  color: $ss-white;
}

.btn--stroke {
  @extend .btn;
  background-color: $ss-white;
  color: $ss-blue-50;
  box-shadow: 0 0 0 1px $ss-blue-50 inset;
}

.btn--borderless {
  @extend .btn;
  background-color: $ss-white;
  color: $ss-blue-50;
  padding: 0;
}

.btn--outline {
  @extend .btn;
  background-color: $ss-white;
  color: $ss-gray-80;
  outline: 1px solid $ss-gray-40;
}

.btn--green-partner {
  background-color: $ss-green-partner;
  &:disabled {
    background-color: $ss-green-partner-20;
  }
}

.exit-button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 14px;
  @include btn-text-4;
  color: $ss-blue-50;

  svg {
    margin-right: 4px;
    fill: $ss-blue-50;
  }
}

.btn-positive-negative{
  @include btn-text-4;
  margin-top: 32px;
  color: $ss-gray-80;

  width: 100%;
  .btn-positive-box{
    display: flex;
    fill: $ss-green-50;
    align-items: center;
    margin-bottom: 16px;
  }
  .btn-negative-box{
    display: flex;
    fill: $ss-red-50;
    align-items: center;
  }
}


.carousel-arrow {
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.50);
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.04), 0 0 2px 0 rgba(0, 0, 0, 0.10);
  cursor: pointer;
  position: absolute;
  user-select: none;

  &.hide {
    display: none;
  }

  &:hover {
    background-color: $ss-gray-10;
  }

  &.sm {
    padding: 6px;
  }
  &.lg {
    padding: 10px;
  }
}

@media (min-width: 1080px) {
  .carousel-arrow {
    &.prev {
      top: 30%;
      left: 10%;
    }
    &.next {
      top: 30%;
      right: 10%;
    }
  }
}

@media (max-width: 1079px) {
  .carousel-arrow {
    &.prev {
      top: 30%;
      left: 20px;
    }
    &.next {
      top: 30%;
      right: 20px;
    }
  }
}