@import "../../base/typo";
@import "../../base/color";
@import "../../base/utils";

.ss-activation-modal {
  .modal-only-body {
    padding: 40px;

    .top {
      .title {
        @include h7-m;
        color: $ss-black;
        text-align: center;
      }
      .sub-title {
        @include body-text-3;
        color: $ss-gray-90;
        margin-top: 12px;
        text-align: center;
      }
    }

    .mid {
      margin-top: 24px;
      text-align: center;
    }
    .bottom {
      margin-top: 24px;
      margin-bottom: 26px;

      label {
        align-items: flex-start !important;
      }

      .label-text {
        @include body-text-3;
        color: $ss-gray-100;
        display: inline;
      }
      .link {
        @include body-text-2;
        color: $ss-blue-50;
        text-decoration: none;
      }

      .text-warning {
        @include caption-text-1;
        color: $ss-red-50;
        margin-top: 4px;
        margin-left: 34px;
      }
    }

  }
}
