@import "../../base/typo";
@import "../../base/color";
@import "../../base/utils";

.ss-after-signup-modal {
  .modal-head {
    @include subtitle-text-2;
    color: $ss-gray-100;
    box-shadow: 0 1px 0 #EEEEEE;

    .content-wrapper {
      padding: 16px 20px;
      text-align: center;
    }
  }
  .modal-body {
    padding: 20px 28px;

    .top {
      margin-bottom: 24px;
      text-align: center;

      .title {
        @include h7;
        color: $ss-gray-100;
      }

      .subtitle {
        @include subtitle-text-2;
        color: $ss-gray-100;
        padding: 24px 0;
      }
    }

    .mid {
      .info-box {
        @include body-text-5-m;
        padding: 20px;
        background-color: $ss-gray-10;
        color: $ss-gray-80;
      }
    }
    .bottom {
      margin-top: 24px;

      .btn-wrapper {
        text-align: center;
      }

      label {
        align-items: flex-start !important;
      }

      .label-text {
        @include body-text-3;
        color: $ss-gray-100;
        display: inline;
      }
      .link {
        @include body-text-2;
        color: $ss-blue-50;
      }

      .text-warning {
        @include caption-text-1;
        color: $ss-red-50;
        margin-top: 4px;
        margin-left: 34px;
      }
    }
    .extra {
      border-top: 1px solid $ss-gray-50;
      padding-top: 24px;
      @include subtitle-text-2;
      color: #454545;
      text-align: center;
    }
  }
}
