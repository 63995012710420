@import "../base/typo";
@import "../base/color";
@import "../base/utils";


@media (min-width: 1080px) {
  .footer-container-pc {
    width: 100%;
    position: absolute;
    background-color: $ss-gray-20;
    @include subtitle-text-1;
    padding: 40px 0;
    color: $ss-gray-80;

    .footer-content-top-pc {
      display: flex;
      justify-content: space-between;
      width: 1080px;
      margin: 40px auto;

      .footer-menu-pc {
        display: flex;

        .footer-menu-col {
          width: 180px;
        }
      }

      .footer-main-menu {
        margin-bottom: 18px;
        gap: 20px;

      }

      .footer-sub-menu {
        @include body-text-3;
        color: $ss-gray-80;
        margin-bottom: 8px;
        white-space: nowrap;
      }

      .link-tag {
        color: $ss-gray-80;
        text-decoration: none;
      }

      .footer-contact-pc {
        float: right;
      }
    }

    .footer-content-bottom-pc {
      border-top: 1px solid #C4C4C4;
      padding-top: 24px;
      width: 1080px;
      margin: 0 auto;

      .footer-logo-pc {
        margin-right: 72px;
      }

      @include caption-text-3;
      color: $ss-gray-60;
    }
  }

  .footer-container-mobile {
    display: none;
  }
}
@media (max-width: 1079px) {
  .footer-container-mobile {
    @include caption-text-3;
    color: $ss-gray-100;

    position: absolute;
    width: 100%;
    padding: 50px 16px;
    text-align: center;
    background-color: $ss-gray-20;

    .footer-logo-mobile{
      margin-bottom: 20px;
    }
    .footer-contact-mobile{
      font-size: 14px;
      line-height: 24px;
      .footer-contact-info-mobile{
        display: flex;
        justify-content: center;
        gap: 10px;
        .footer-contact-text-mobile{
          display: flex;
          gap: 4px;
          align-items: center;
          .icon{
            padding-top:5px;
          }
        }
      }
    }
    .footer-text{
      margin-bottom: 10px;
      color:$ss-gray-60;
    }
    .last-footer-text{
      margin-bottom: 30px;
    }
    .footer-copyright-mobile{
      padding:16px;
      border-top:1px solid $ss-gray-40;
    }
  }
  .footer-container-pc{
    display: none;
  }
}