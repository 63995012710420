@import "../base/typo";
@import "../base/color";
@import "../base/utils";

@media (min-width: 1080px) {
  .mobile {
    display: none !important;
  }

  .ssheader {
    .ssheader-container {
      width: 1080px;

      .header-left {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 0;

        .header-company-info {
          display: flex;
          gap: 16px;
          .header-company-logo {
            img {
              border:1px solid $ss-gray-30;
              border-radius: 4px;
            }
          }

          .header-company-name {
            @include btn-text-1;
            color: $ss-white;
            margin-right: 32px;
            display: flex;
            align-items: center;
          }
        }
        .header-left-menu {
          display: flex;
          align-items: flex-start;
          padding: 0;
          gap: 32px;
        }
      }

      .header-right {
        .header-right-menu {
          .button-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;

            .sign-in-button, .sign-up-button {
              @include btn-text-3;

              border: none;
              border-radius: 4px;
              -webkit-appearance: none;
              -webkit-border-radius: 4px;
              padding: 6px 16px;
              text-decoration: none;
              cursor: pointer;

              &.sign-in-button {
                color: $ss-blue-50;
              }
              &.sign-up-button {
                color: $ss-white;
                background-color: $ss-blue-50;
              }
            }
          }
        }

        .header-right-sep {
          height: 26px;
          border: 1px solid $ss-blue-20;
        }

      }
    }
  }
}

@media (max-width: 1079px) {
  .pc {
    display: none !important;
  }

  .ssheader {
    z-index: 999;

    .ssheader-container {
      padding: 0 22px 0 12px;
    }

    .sidebar {
      z-index: 999;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      visibility: hidden;
      background-color: rgba(0,0,0,0);

      &.is-block{
        visibility: visible;
        background-color: rgba(0,0,0,0.5);
      }
      &.is-other-service-sidebar{
        top: 64px;
      }
    }

    .sidebar-content {
      @include body-text-1;
      color: $ss-gray-100;
      background-color: $ss-white;
      height: 100%;
      width: 80%;
      max-width: 288px;
      top: 0;
      z-index: 2;

      &.isBlock {
        position: absolute;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        right: 288px;
        transform: translateX(288px);
        transition: .3s ease all;
        -webkit-transform: translateX(288px);
        -webkit-transition: .3s ease all;
        visibility: visible;
      }
      &.isNotBlock {
        float: right;
        right: 0;
        transform: translateX(-100%);
        transition: 0s ease all;
        -webkit-transform: translateX(105%);
        -webkit-transition: 0s ease all;
      }

      .sidebar-content-menu {
        display: block;
        border-bottom: 1px solid $ss-gray-30;
        padding-left: 0;

        .mobile-sidebar-top {
          text-align: center;
          margin-top: 44px;

          .mobile-sidebar-top-text {
            @include body-text-3;
            color: $ss-gray-100;
            margin-top: 20px;
          }
        }
        .button-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 20px 24px;

          .sign-in-button, .sign-up-button {
            @include btn-text-2;
            display: block;
            width: 100%;
            padding: 10px 0 12px;
            cursor: pointer;
            border: 1px solid transparent;
            border-radius: 4px;
            text-align: center;

          }
          .sign-in-button {
            background-color: $ss-gray-30;
            color: $ss-gray-100;
          }
          .sign-up-button {
            background-color: $ss-blue-50;
            color: $ss-gray-30;
          }
        }
      }
    }
  }

  .ssheader-snb {
    .container {
      padding: 0 12px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}


.ssheader {
  width: 100%;
  background-color: $ss-white;

  color: $ss-white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .ssheader-container {
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-menu-link {
    color: $ss-gray-90;
  }
  .sign-in-button {
    color: $ss-blue-50;
    background-color: $ss-white;
    text-decoration: none;
  }
  .sign-up-button {
    &.is-company-landing {
      background-color: $ss-blue-50;
      color: $ss-white;
    }
    text-decoration: none;
  }
  .header-right-sep {
    border: 1px solid $ss-blue-20;
  }
  .link-toggle-service {
    color: $ss-blue-50;
    border:1px solid $ss-blue-50;;
  }
}

.mobile-hamburger-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  .sign-up-button-external {
    @include btn-text-4;
    color: $ss-white;
    background-color: $ss-blue-50;
    border: 1px solid $ss-blue-50;
    border-radius: 54px;

    button {
      background-color: transparent;

      .link-text {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.logo {
  position: relative;
  display: flex;
  align-items: center;

  @media (min-width: 1080px) {
    svg {
      margin: 2px 0;
    }
  }
}

.header-menu-link {
  @include body-text-3;
  color: $ss-gray-90;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  &.active {
    text-decoration: underline;
    text-underline-offset: 5px;
  }
}

.ssheader-snb {
  // todo: ssheader 하위에 넣기. header 레거시 제거 필요
  height: 48px;
  padding: 0;
  border-bottom: 1px solid $ss-gray-40;
  width: 100%;
  display: flex;
  align-items: center;

  .container {
    overflow-x: auto;
    white-space: nowrap;
  }

  .sub-navigation {
    div {
      padding: 12px 20px;
      height: 100%;
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: $ss-gray-80;
    }

    &:hover {
      div {
        color: $ss-gray-100;
      }
    }

    &.active {
      div {
        font-weight: 500;
        padding: 12px 0 9px 0;
        margin: 0 20px;
        color: $ss-gray-100;
        border-bottom: solid 3px $ss-blue-50;
      }
    }

  }
}