@import "../base/typo";
@import "../base/color";
@import "../base/utils";

@media (min-width: 1080px) {
  .SSheader {
    background-color: $ss-gray-100;

    .SSheader-container {
      width: 1080px;
      height: 64px;

      .title{
          display: none;
        }
      .icon-arrow {
          display: none;
        }

      .header-menu {
        @include body-text-2;
        color: $ss-white;
      }

      .header-btn {
        @include btn-text-3;
        color: $ss-white;
      }

      .header-link {
        text-decoration: none;
        cursor: pointer;
      }
      .header-link:hover {
        text-decoration: underline;
        text-underline-position: under;
      }

      .header-left {
        flex-direction: row;
        align-items: center;
        padding: 0;
        position: absolute;
        height: 64px;
        top: 0;
        gap: 20px;
        display: flex;
        .header-company-info {
          display: flex;
          gap: 16px;
          .header-company-logo {
            img {
              border:1px solid $ss-gray-30;
              border-radius: 4px;
            }
          }

          .header-company-name {
            @include btn-text-1;
            color: $ss-white;
            margin-right: 32px;
            display: flex;
            align-items: center;
          }
        }
        .header-left-menu {
          flex-direction: row;
          align-items: flex-start;
          padding: 0;
          gap: 32px;
          display: flex;
        }
      }

      .header-right {
        display: inline-flex;
        align-items: center;
        gap: 16px;
        height: 64px;
        float: right;

        &.mobile {
          display: none;
        }

        .mobile-sidebar-top{
          display: none;
        }
        .header-icon-box {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          padding: 0 12px;

          .header-icon {
            width: 24px;
            height: 24px;
            fill: $ss-white;
          }
          .icon-name{
            display: none;
          }

        }

        .header-right-menu {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          padding: 0;
          height: 32px;
          letter-spacing: 0.005em;
          color: $ss-white;

          .button-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;

            .sign-in-button, .sign-up-button {
              @include btn-text-3;
              color: $ss-white;
              background-color: $ss-gray-100;
              border: none;
              border-radius: 4px;
              -webkit-appearance: none;
              -webkit-border-radius: 4px;
              padding: 6px 16px;
              text-decoration: none;
              cursor: pointer;

              &.sign-in-button {
                background-color: $ss-gray-90;
                &.is-company-landing {
                  color: $ss-white;
                  background-color: rgba(255, 255, 255, 0.3);
                }
              }

              &.sign-up-button {
                background-color: $ss-blue-50;
                &.is-company-landing {
                  color: $ss-gray-90;
                  background-color: $ss-white;
                }
              }

              .header-link {
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }

        .header-right-sep {
          height: 26px;
          border: 1px solid $ss-gray-80;
        }

        .header-user-image-box:hover {
          .user-dropdown-menu {
            display: block;
          }
        }

        .header-user-image-box {
          display: flex;
          margin: 0 auto;
          position: relative;
          cursor: pointer;
          height: 100%;
          align-items: center;
          .header-user-image {
            img {
              border-radius: 16px;
              border: 1px solid $ss-gray-30;
            }
          }

          .user-dropdown-menu {
            display: none;
            z-index: 1;
            position: absolute;
            background-color: $ss-white;
            border-radius: 8px;
            color: $ss-gray-100;
            width: 200px;
            filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
            cursor: pointer;
            top: 64px;
            right: 0;
            padding-bottom: 8px;
            margin-right: auto;
            &.is-dropdown-open{
              display: block;
            }
            .user-name-dropdown {
              height: 56px;
              @include body-text-2;
              border-bottom: 1px solid;
              border-color: $ss-gray-30;
              cursor: default;
              display: flex;
              align-items: center;
              gap: 12px;
              .user-name-dropdown-div {
                padding-left: 16px;
              }
            }

            .selected-dropdown-menu:hover {
              background-color: $ss-gray-10;
            }

            .header-user-dropdown-image {
              width: 24px;
              height: 24px;
              border-radius: 24px;
            }
            .header-dropdown-icon {
              width: 16px;
              height: 15px;
              vertical-align: middle;
              fill: $ss-gray-80;
            }

            .selected-dropdown-menu{
              @include body-text-2;
              align-items: center;
              display: flex;
              height: 40px;
              padding: 0 20px;
              gap: 16px;
              

              .header-dropdown-menu{
                @include body-text-2;
              }
              .header-dropdown-logout{
                stroke: $ss-gray-80;
                fill: none;
              }
              &.is-company{
                display: none;
              }
              &.user-logout-dropdown-pc{
                margin-top: 4px;
              }
            }
            .header-dropdown-menu-box{
              padding: 4px 0;
              border-bottom: 1px solid $ss-gray-30;
            }

            .user-name-dropdown-div {
              vertical-align: middle;
            }

            tr td:first-child {
              padding-left: 20px;
            }
            .header-dropdown-category, .category-box{
                display: none;
              }
            .profile-image-edit-icon{
              cursor: pointer;
            }
          }
          .header-sidebar-menu-only-mobile{
            display: none;
          }
        }

      }
    }
  }
  .header-is-company {
    background-color: $ss-gray-90;
  }
  .logout-icon-order {
    display: none;
  }
  .mobile-icon-hamburger{
    display: none;
  }
  .profile-img-modal-box {
    .profile-img-form {
      .btn-box-profile-img {
        display: flex;
        justify-content: center;
        gap: 16px;
        position: relative;
        .btn-profile-img {
          display: flex;
          text-align: center;
          font-size: 14px;
          line-height: 24px;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 120px;
          border:1px solid $ss-gray-40;
          border-radius: 4px;
          cursor: pointer;
          color:$ss-gray-60;
          gap:4px;
        }
      }
    }
    .profile-img-text {
      font-size: 14px;
      line-height: 24px;
      padding: 24px 32px 0 32px;
      border-top:1px solid $ss-gray-40;
      color: $ss-gray-100;
    }
  }
  .profile-img-box {
    text-align: center;
    padding: 24px;
    .profile-img {
      border-radius: 90px;
      border: 1px solid $ss-gray-40;
    }
  }
  .profile-help-text-box {
    padding: 12px 32px 0 32px;
    margin-top: 32px;
    justify-content: center;
    align-items: center;
    background-color: $ss-gray-10;
    border-bottom: 1px solid $ss-gray-40;
    .profile-help-text {
      @include caption-text-3;
      color: $ss-gray-70;
      height: 20px;
      margin-bottom: 4px;
    }
  }

  .SSheader-blue {
    .SSheader-container {
      .header-right {
        .header-right-menu {
          .sign-in-button {
            background-color: rgba(255, 255, 255, 0.3) !important;
          }

          .sign-up-button {
            background-color: $ss-white !important;
            .header-link {
              color: $ss-blue-50 !important;
            }
          }
        }
        .header-right-sep {
          border: 1px solid $ss-blue-20;
        }
      }
    }
  }

  .job-seeker-links-mobile {
    display: none;
  }
}

@media (max-width: 1079px) {
  .sidebar{
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    background-color: rgba(0,0,0,0);

    &.is-block{
      visibility: visible;
      background-color: rgba(0,0,0,0.5);
    }
    &.is-other-service-sidebar{
      top: 64px;
    }
  }

  .SSheader {
    z-index: 999;

    .SSheader-container{
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;

      &.container-not-landing{
        display: flex;
      }
      .icon-arrow {
        width: 24px;
        cursor: pointer;
      }
      .title{
        @include h7;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 24px;
        margin-right: 24px;
      }
      .header-left{
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 60px;
        .header-company-info {
          display: none;
        }
        .logo{
          display: flex;
          font-size: 20px;
          align-items: center;
        }
        .not-landing-logo{
          display: none;
        }

        .header-left-menu{
          display: none;
        }
      }
      .mobile-icon-hamburger-right{
        position: absolute;
        right: 24px;
      }
    }
  }
  .header-not-landing{
    background-color: $ss-white;
  }
  .first-menu{
    border-top: 1px solid $ss-gray-30;
  }
  .last-menu{
    border-bottom: 1px solid $ss-gray-30;
  }
  .header-icon{
    width: 20px;
    height: 20px;
    fill: $ss-gray-80;
  }
  .logout-icon-order{
    display: flex;
    order:3;
    height: 100%;
    align-items: end;
    padding-left: 20px;
    gap: 7px;
    color: $ss-gray-60;
    @include body-text-2;
    .header-dropdown-icon {
      stroke: $ss-gray-60;
    }
  }
  .header-right{
    &.pc {
      display: none;
    }
        @include body-text-1;
        color: $ss-gray-100;
        height: 100%;
        width: 80%;
        max-width: 288px;
        flex-direction: column;
        top: 0;
        z-index: 2;
        background-color: $ss-white;
        position: absolute;
        overflow-y: scroll;
        &.isBlock {
          position: absolute;
          overflow-y: auto;
          display: flex;
          right: 288px;
          transform: translateX(288px);
          transition: .3s ease all;
          -webkit-transform: translateX(288px);
          -webkit-transition: .3s ease all;
          visibility: visible;
        }
        &.isNotBlock {
          float: right;
          display: flex;
          transform: translateX(-100%);
          transition: 0s ease all;
          -webkit-transform: translateX(105%);
          -webkit-transition: 0s ease all;
          right: 0;
        }
        .header-right-menu {
          padding-bottom: 24px;
          border-bottom: 1px solid $ss-gray-30;
          padding-left: 0;
          display: block;

          .mobile-sidebar-top{
            padding-left: 24px;
            font-size: 24px;
            border-bottom: 1px solid $ss-gray-30;
          }
          .sign-in-button{
            background-color: $ss-blue-50;
            order:2;
            margin: 16px 0;
            .header-link{
              color: $ss-gray-30;
            }
          }
          .sign-up-button{
            background-color: $ss-gray-30;
            color: $ss-gray-100;
            order:1;
            .header-link{
              color: $ss-gray-100;
            }
          }
          .sign-in-button, .sign-up-button{
            width: 240px;
            height: 40px;
            border: none;
            -webkit-appearance: none;
            text-decoration: none;
          }
          .button-wrapper{
            padding-left:24px;
          }
          .header-link{
            text-decoration: none;
          }
        }
        .header-icon-box{
          display: block;
          .header-icon-div {
            display: flex;
            align-items: center;
            gap:18px;
            .header-icon {
              height: 56px;
            }
            &.header-icon-pc{
              display: none;
            }
          }
          .icon-name{
            @include subtitle-text-1;
          }
        }
        .header-icon-box-order{
          order: 2;
          padding-left: 20px;
        }

        .header-user-image-box{
          .header-user-image{
            display: none;
        }
          .user-dropdown-menu{
            .user-name-dropdown{
              align-items: center;
              display: flex;
              border-bottom: 1px solid $ss-gray-30;
              height: 80px;
              padding-left: 20px;
              gap: 12px;
                .header-user-dropdown-image{
                  border:1px solid $ss-gray-30;
                  border-radius: 32px;
                  width: 32px;
                  height: 32px;
                }
              .profile-image-edit-icon{
                display: none;
              }
            }
            .header-dropdown-menu-box{
              border-bottom: 1px solid $ss-gray-30;
              border-top:1px solid $ss-gray-30;
            }
            .category-angle{
              transform: rotate(90deg);
              &.is-angle{
                transform: rotate(-90deg);
              }
            }
            .category-box{
                padding-left: 64px;
                height: 233px;
                display: none;
              &.is-open {
                display: block;
                .category {
                  @include body-text-2;
                  height: 24px;
                  display: block;
                  color: $ss-gray-80;
                  margin-bottom: 8px;
                  text-decoration: none;
                }
              }
              }

            .selected-dropdown-menu {
              height: 56px;
              align-items: center;
              display: flex;
              padding: 0 20px;
              .header-dropdown-menu{
                @include subtitle-text-1;
                padding-left: 18px;
                width: 100%
              }
              .header-dropdown-icon {
                fill: $ss-gray-80;
              }
            }
            .user-logout-dropdown-pc{
                display: none;
        }
            .header-sidebar-menu-only-mobile{
              @include subtitle-text-1;
              padding:16px 24px;
            }
          }

        }
        .user-logout-dropdown{
          padding: 24px;
          .logout-box{
            @include body-text-2;
            color: $ss-gray-60;
            width: 212px;
          }
        }
        .user-logout-dropdown-pc{
          display: flex;
          align-items: center;
          gap: 7px;
          color: $ss-gray-60;
        }
        .header-right-menu{
          .selected-service{
            display: none;
          }
        }
        .header-user-dropdown-image{
          width: 16px;
          height: 16px;
        }
      }
  .profile-img-modal-wrapper {
    height: 100%;
    .profile-img-modal-box {
      height: calc(100% - 76px);
      .profile-img-form {
        text-align: center;
        margin-bottom: auto;

        .btn-box-profile-img {
          display: flex;
          justify-content: center;
          gap: 16px;
          position: relative;

          .btn-profile-img {
            display: flex;
            text-align: center;
            font-size: 14px;
            line-height: 24px;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 120px;
            border: 1px solid $ss-gray-40;
            border-radius: 4px;
            cursor: pointer;
            color: $ss-gray-60;
            gap: 4px;
          }
        }
      }

      .profile-img-text {
        font-size: 14px;
        line-height: 24px;
        padding: 24px 32px 0 32px;
        border-top: 1px solid $ss-gray-40;
        color: $ss-gray-100;
        text-align: center;
      }
    }
  }
  .profile-img-box {
    justify-content: center;
    padding: 24px;
    .profile-img {
      border-radius: 90px;
      border: 1px solid $ss-gray-40;
    }
  }
  .profile-help-text-box {
    padding: 12px 32px 0 32px;
    justify-content: center;
    align-items: center;
    background-color: $ss-gray-10;
    border-bottom: 1px solid $ss-gray-40;
    .profile-help-text {
      @include caption-text-3;
      color: $ss-gray-70;
      height: 20px;
      margin-bottom: 4px;
    }
  }

  .SSheader-blue {
    .SSheader-container {
      .header-right {
        .header-right-menu {
          .sign-in-button {
            background-color: $ss-blue-50 !important;
            .header-link {
              color: $ss-white !important;;
            }
          }

          .sign-up-button {
            background-color: $ss-gray-30 !important;;
            .header-link {
              color: $ss-gray-100 !important;;
            }
          }
        }
      }
    }
  }

  .job-seeker-links-mobile {
    order: 1;

    .link {
      @include subtitle-text-1;
      padding:16px 24px;
      border: 1px solid transparent;

      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}


.SSheader {
  width: 100%;
  color: $ss-white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .SSheader-container {
    margin: 0 auto;
  }
}

.SSheader-blue {
  @extend .SSheader;
  background-color: $ss-blue-50;

  .SSheader-container {
    .header-right {
      .header-right-menu {
        .sign-in-button {
          background-color: rgba(255, 255, 255, 0.3);
        }

        .sign-up-button {
          background-color: $ss-white;
          .header-link {
            color: $ss-blue-50;
          }
        }
      }
    }
  }
}

.header-is-gray {
  background-color: $ss-gray-90 !important;
}

.link-toggle-service {
  @include btn-text-4;
  color: $ss-white;
  border:1px solid $ss-white;
  border-radius: 24px;

  &.is-blue {
    color: $ss-blue-50;
    border:1px solid $ss-blue-50;
  }

  display: flex;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  padding: 7px 12px 5px;
  cursor: pointer;
  text-decoration: none;
}

.header-menu-link {
  @include body-text-2;
  color: $ss-white;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  &.active {
    text-decoration: underline;
    text-underline-offset: 5px;
  }
}

.logo {
  display: flex;
  align-items: center;
  padding-right: 16px;
  font-size: 24px;
}

.other-services {
  @include caption-text-1;
  position: absolute;
  background-color: $ss-white;
  cursor: pointer;
  padding-top:12px;
  line-height: 18px;
  color: $ss-gray-80;
  display: none;

  &.is-other-service-open {
    display: block;
  }

  .other-services-text {
    padding: 0 20px;
  }

  .other-services-wrapper {
    display: flex;
    padding: 12px 20px;
    &.first-service{
      border-bottom: 1px solid $ss-gray-30;
    }
    .other-services-logo {
      padding-right: 20px;
    }

    .other-services-name {
      @include body-text-3;
      color: $ss-gray-100;
    }

    .other-services-info {
      @include caption-text-1;
      color: $ss-gray-80;
    }
  }

  @media (min-width: 1080px) {
    min-width: 232px;
    border-radius: 8px;
    top: 54px;
    left: 0;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
    z-index: 2;
  }
  @media (max-width: 1079px) {
    z-index: 4;
    width: 100%;
  }
}